import axios from 'axios' // 引用axios
import Qs from 'qs'
import cache from "./cache"
import config from "./config";
const utils = require("./utils");
import {Message} from "element-ui";
// axios 配置
// 设置响应超时
axios.defaults.timeout = 60000
// axios.defaults.baseURL = 'https://easy-mock.com/mock/' // 这是调用数据接口,公共接口url+调用接口名
let httpUrl = window.location.host;
// 这是调用数据接口,公共接口url+调用接口名
axios.defaults.uploadURL = config.upload_url; // 这是调用数据接口,公共接口url+调用接口名
if (httpUrl.indexOf("localhost") || httpUrl.indexOf("192.168")) {
    axios.defaults.baseURL = config.test_url; // 这是调用数据接口,公共接口url+调用接口名
} else {
    axios.defaults.baseURL = config.release_url; // 这是调用数据接口,公共接口url+调用接口名
}
// http request 拦截器，通过这个，我们就可以把token传到后台
axios.interceptors.request.use(
    config => {
        let user_token = cache.get('token');
        if (config.method == 'get') {
            config.headers = {
                'token': user_token,
            }
            config.data = Qs.stringify(config.data)
            return config
        } else {
            config.headers = {
                //multipart/form-data
                //
                'Content-Type': config.headers.content_type, // 设置跨域头部
                'token': user_token,
            }
            // config.data = Qs.stringify(config.data)
            return config
        }
    },
    err => {
        return Promise.reject(err)
    }
)

// http response 拦截器
axios.interceptors.response.use(response => {

        if (response.status === 200) {
            // 成功
            let returnCode = response.data.code;
            if (returnCode == 99) {
                Message({
                    type: 'error',
                    message: '登陆超时，请重新登录',
                    onClose: function () {
                        utils.jumpUrl("/login")
                    }
                });
            }
            // returnCode=401;
            if (returnCode > 10000 && returnCode < 20000) {
                // console.log('成功', response)
                Message.success(response.data.msg)
            } else if (returnCode >= 20000 && returnCode < 30000) {
                // 只弹窗，不操作
                // console.log('失败1', response)
                Message.error(response.data.msg)
            } else if (returnCode >= 30000 && returnCode < 40000) {
                // 只弹窗，点击跳到登入页
                // localStorage.removeItem('userInfo')

            }
        } else if (response.status == 401) {
            console.log("登陆超时，请重新登录")

            return 'error';
        }
        if (response.status == 99) {
            console.log("登陆超时，请重新登录")
            return 'error';
        }
        return response
    }, error => {
        if (error.toString().trim() === "TypeError: Cannot read property 'cancelToken' of null") {
            console.log("会话凭证失效，可以取消继续留在该页面，或者重新登录")
        }
        if (error.toString().trim() === 'Error: Network Error') {
            console.log("网络请求异常，请稍后重试', '出错了");
        }
        return Promise.reject(error.response.data)
    }
)

export default axios

/**
 * fetch 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(axios.defaults.baseURL + url, {
            params: params
        }).then(response => {
            resolve(response.data)
        }).catch(err => {
            if (err.code == 99) {
                console.log("登陆超时，请重新登录");
                return false;
            }
            reject(err)
        })
    })
}

/**
 * post 请求方法
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */
export function post(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
        axios.post(axios.defaults.baseURL + url, data, config).then(
            response => {
                // console.log(response.data.code)
                resolve(response.data)
            },
            err => {
                if (err.code == 99) {
                    console.log("登陆超时，请重新登录")
                    return false;
                }
                reject(err)
            }
        )
    })
}

export function download(url, param = {}, config = {}) {

    let instance = axios.create({
        timeout: 60000,
    })
    return new Promise((resolve, reject) => {
        let user_token = cache.get('token');
        config.responseType = 'blob';
        config.headers = {
            token: user_token
        };
        instance.post(url, param, config).then(res => {
            console.log(res);
            const {data, headers} = res;
            // const fileName = headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
            const fileName = param.title;
            const blob = new Blob([data], {type: headers['content-type']})
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(fileName)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
        })
    })
}

/**
 * post 请求方法
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */
export function upload_post(url, data = {}, config = {}) {
    let token = cache.get('token');
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', data);
        formData.append('token', token);

        let instance = axios.create({
            baseURL: axios.defaults.uploadURL,
            timeout: 60000,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        instance.post('', formData).then(
            response => {
                // console.log(response.data.code)
                resolve(response.data)
            },
            err => {
                if (err.code == 99) {
                    console.log("登陆超时，请重新登录")

                    return false;
                }
                reject(err)
            }
        )
    })
}

export function run(url, method, data = {}, config = {}) {
    let instance = axios.create({
        timeout: 60000,
        //method: method,
    })
    if (method == 'get') {
        return new Promise((resolve, reject) => {
            instance.get(url, {
                params: data
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })


        })
    }
    if (method == 'post') {
        return new Promise((resolve, reject) => {
            instance.post(url, data, config).then(
                response => {
                    // console.log(response.data.code)
                    resolve(response.data)
                },
                err => {
                    console.log(err);
                    reject(err)
                }
            )
        })
    }
    return new Promise((resolve, reject) => {
        reject(false);
    })

}

