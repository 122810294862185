<template>
  <transition name="fade" v-if="isShow">
    <!-- //success warn err default -->
      <div class="tip-box"
           :class="{'success':type =='success' ,'warn':type =='warn' ,'err':type =='err','default':type =='default'}" v-if="isShow">
        <div class="tip-box-content">
          {{content}}
        </div>
      </div>
  </transition>
</template>

<script>
export default {
  name: "JayMessage",
  props:{
    content:{//消息内容
      type:String,
      default:'',
    },
    type:{//显示类型  success warn err default
      type:String,
      default:'success',//success warn err default
    },
    timer:{//显示时间
      type:Number,
      default:3000,
    },
    onClose:{
      type:Function,
      default:()=>{}
    }
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    show() {//组件显示方法
      this.isShow = true;
      setTimeout(this.hide, this.timer);
    },
    hide() {//组件隐藏方法
      this.isShow = false;
      if( typeof this.onClose =="function") this.onClose();
      this.remove(); //当被挂载在create.js上是，this.remove()即执行comp.remove()，即从body上移除掉挂载的真实dom，并销毁虚拟dom
    }
  }

}
</script>

<style scoped lang="scss">
.tip-box {
  position: fixed;
  left: 35%;
  top: 10%;
  font-size: 14px;
  width: 30%;
  padding: 10px 0;
  border-radius: 10px;
  letter-spacing: 1px;
  text-align: center;
  z-index: 99999999;
  color: #fff;
  .tip-box-content{
    width: 90%;
    margin: 0 auto;
  }
}
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;

  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .success{
    background: rgba(70, 52, 90, 0.8);
    border: 1px solid #C598FF;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
  }
  .warn{
    background: #E6A23C;

  }
  .err{
    background: #e24747;

  }
  .default{
    background: #909399;
  }



</style>