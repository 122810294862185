import {get,post} from "./http"
import {api_url} from './config'
import {checkCache,randomString} from "./utils"
import cache from "./cache"
import store from '@/store/index'
import router from "../router";
import config from "./config";
import {Message} from "element-ui";

export function UserInit(){
    let token=cache.get('token');
    return new Promise(function(resolve, reject) {
        // 异步处理  // 处理结束后、调用resolve 或 reject
        if(checkCache(token)){ //如果token未过期
            refreshToken().then((info)=>{
                resolve(info);
            }).catch((err)=>{
                reject(err);
            })
        }else{
            reject(false);
        }
    });
}

export function getUserInfo(){
    return new Promise(function (resolve, reject) {
        get('/user_info/info',{}).then((res)=>{
            if(res.code==0){
                let token=cache.get('token');
                cache.set(token,res.data);
                store.commit('setUserInfo',res.data)
                resolve(res.data);
            }else{
                reject(false);
            }
        }).catch((err)=>{
            reject(err);
        })
    });
}
export function UpdateUserInfo(data){

}

export function checkLogin(){
    router.beforeEach((to, from, next) => {
        let path=to.path;
        let exclude_path=config.exclude_path;
        let share_regex=config.share_regex;
        if('code' in to.params){
            cache.set('sharecode',to.params.code);
        }
        if('code' in to.query){
            cache.set('sharecode',to.query.code);
        }
        if(exclude_path.indexOf(path)==-1 && !share_regex.test(path) ){
            UserInit().then(info => {
                //next();
            }).catch(err => {
                let reg = to.query.reg;
                if(reg==null || reg==undefined) reg='';
                Message({
                    message: '请先登陆',
                    type:'error',
                    onClose() {
                        // next({path: '/login?reg=' + reg})
                        next({path: '/login'})
                    }
                });
                console.log(err)
                return;
            });
        }

        /* 路由发生变化修改页面title */
        // @ts-ignore
        if (to.meta.title) {
            // @ts-ignore
            document.title = to.meta.title
        }
        if (to.path == '/') {
            // @ts-ignore
            // let param = new URLSearchParams(to.query).toString(); //携带参数
            // let param='';
            // next('/share_data?' + param);
            // next('/home?' + param);
            next('/home');
        }
        next();
        window.scrollTo(0, 0)
    })
}

export function refreshToken(){
    return new Promise(function (resolve, reject) {
        post('/user/checkToken',{}).then((res)=>{
            if(res.code==0){
                let token=cache.get('token');
                cache.set(token,res.data);
                store.commit('setUserInfo',res.data);
                store.commit('setLoginStatus',true);
                resolve(res.data);
            }else{
                reject(false);
            }
        }).catch((err)=>{
            reject(err);
        })
    });
}


