import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Router from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "about" */ '../views/common/home.vue'),
                meta: {
                    keep_alive: false,
                },
            },
            {
                path: '/home/:code',
                name: 'home/',
                component: () => import(/* webpackChunkName: "about" */ '../views/common/home.vue'),
                meta: {
                    keep_alive: false,
                },
            },
            {
                path: '/chat',
                name: 'chat',
                component: () => import(/* webpackChunkName: "about" */ '../views/chat/chat.vue'),
                meta: {
                    keep_alive: false,
                },
            },
            {
                path: '/chat/v2',
                name: 'chat/v2',
                component: () => import(/* webpackChunkName: "about" */ '../views/chat/chatv2.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/chat/code',
                name: 'chat/code',
                component: () => import(/* webpackChunkName: "about" */ '../views/chat/codeChat.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/picture',
                name: 'picture',
                component: () => import(/* webpackChunkName: "about" */ '../views/chat/picture.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/my/article',
                name: 'my/article',
                component: () => import(/* webpackChunkName: "about" */ '../views/userArticle/lists.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/release/article',
                name: 'release/article',
                component: () => import(/* webpackChunkName: "about" */ '../views/release/articles.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/release/release',
                name: '/release/release',
                component: () => import(/* webpackChunkName: "about" */ '../views/release/release.vue'),
                meta: {
                    keep_alive: true,
                },
            }, {
                path: '/release/order',
                name: '/release/order',
                component: () => import(/* webpackChunkName: "about" */ '../views/release/order.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/user',
                name: '/user',
                component: () => import(/* webpackChunkName: "about" */ '../views/user/info.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/my/release/article',
                name: 'my/release/article',
                component: () => import(/* webpackChunkName: "about" */ '../views/user/releaseArticle.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/my/invite',
                name: 'my/invite',
                component: () => import(/* webpackChunkName: "about" */ '../views/user/invite.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/recharge',
                name: 'recharge',
                component: () => import(/* webpackChunkName: "about" */ '../views/user/recharge.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/user/chat/history',
                name: 'user/chat/history',
                component: () => import(/* webpackChunkName: "about" */ '../views/user/chatHistory.vue'),
                meta: {
                    keep_alive: false,
                },
            }, {
                path: '/articles',
                name: 'articles',
                component: () => import(/* webpackChunkName: "about" */ '../views/common/articles.vue'),
                meta: {
                    keep_alive: false,
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/common/login.vue')
    }, {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "about" */ '../views/common/register.vue')
    }, {
        path: '/forget',
        name: 'forget',
        component: () => import(/* webpackChunkName: "about" */ '../views/common/forget.vue')
    },
    {
        path: '/screen',
        name: 'screen',
        component: () => import(/* webpackChunkName: "about" */ '../views/device/show.vue'),
        meta: {
            title: '爱喳猫',
            isFresh: false,
        }
    }, {
        path: '/screen/login',
        name: 'screen/login',
        component: () => import(/* webpackChunkName: "about" */ '../views/device/login.vue'),
        meta: {
            title: '登陆',
            isFresh: false,
        }
    },{
        path: '/m',
        name: 'mobile',
        component: () => import(/* webpackChunkName: "about" */ '../views/common/mobile.vue'),
        meta: {
            title: '登陆',
            isFresh: false,
        }
    },{
        path: '/ai',
        name: 'ai',
        component: () => import(/* webpackChunkName: "about" */ '../views/common/AICustomer.vue'),
        meta: {
            title: 'AIChat',
            isFresh: false,
        }
    },
    // {
    //   path: '/my/article',
    //   name: 'my/article',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/userArticle/lists.vue')
    // },{
    //   path: '/user/article/info',
    //   name: 'user/article/info',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/userArticle/contents.vue')
    // },
    // {
    //   path: '*',
    //   name: 'page404',
    //   redirect:"404",
    //   component: () => import(/* webpackChunkName: "about" */ '../views/common/page404.vue')
    // }
]
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    // @ts-ignore
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach(async (to, from, next) => {
//   if (to.path) {
//     if (window._hmt) {
//       window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
//     }
//   }
//   next();
// });

export default router
