import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import '@/assets/css/base.css'
import config from '@/utils/config'
import {get, post,download,run} from "./utils/http";
import cache from "./utils/cache";

import {goBack, jumpUrl, formatDate, toThousands} from "./utils/utils";
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
const utils = require("./utils/utils");
import VueClipboard from 'vue-clipboard2'
import {checkLogin} from "./utils/user.js";
//上线才用
import 'lib-flexible/flexible.js'
import '@/utils/rem'

import jayAlert from './components/message/JayAlert/index';
import jayPrompt from './components/message/JayPrompt/index';
import jayMessage from './components/message/JayMessage/index';

Vue.use(jayAlert);
Vue.use(jayPrompt);
Vue.use(VueClipboard);
Vue.prototype.$jayMessage = jayMessage;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$run = run;
Vue.prototype.$download = download;
Vue.prototype.$cache = cache;
Vue.prototype.$jumpUrl = jumpUrl;
Vue.prototype.$utils = utils;
Vue.config.productionTip = false;
Vue.use(element);
checkLogin();

//检查是否登陆
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
